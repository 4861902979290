import React, { useEffect, useRef } from 'react';

import * as indexStyles from './index.module.css';

import { setPreset } from '../three/src/main';
//import { gsap } from 'gsap';

import { Helmet } from "react-helmet"

const IndexPage = ({ transitionStatus }) => {
    const container = useRef(null);

    useEffect(() => {
        setPreset(0);
        //gsap.to(container.current, { opacity: 1, duration: 1, ease: "power1.in", delay: 0.2 })
    }, [])

    useEffect(() => {
        if(transitionStatus === 'exiting') {
            //gsap.to(container.current, { opacity: 0, duration: 1 })
        }
    }, [transitionStatus]);

    return (
        <>
            <Helmet>
                <title>Luka Batista</title>
            </Helmet>
            <div className={indexStyles.container} ref={container}>
                <div className={indexStyles.box}>
                    <div className={indexStyles.content}>
                        {/* nothing here at the moment */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default IndexPage;